$('.abreModal').on('click', function(){
    var modal = $(this).data('modal');
    var caminho = 'views/modais';

    $.get(caminho + '/' + modal + '.html', function( data ) {
        $('.modal').html(data);
        $('.modal').fadeIn();
        $('body').addClass('blockScroll');
        closeModal();
    });
});

function closeModal() {
    $('.close').on('click', function(){
        $('.modal').fadeOut();
        $('body').removeClass('blockScroll');
        $('.modal').html('');
    });
}