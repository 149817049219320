//Valida E-mail
function IsEmail(email) {
    var exclude = /[^@\-\.\w]|^[_@\.\-]|[\._\-]{2}|[@\.]{2}|(@)[^@]*\1/;
    var check = /@[\w\-]+\./;
    var checkend = /\.[a-zA-Z]{2,3}$/;
    if (
        email.search(exclude) != -1 ||
        email.search(check) == -1 ||
        email.search(checkend) == -1
    ) {
        return false;
    } else {
        return true;
    }
}

//Envia E-mail Contato
$("#formContato").submit(function(e) {
    e.preventDefault();
    $("#btnEnviarContato").html(
        'Aguarde. Enviando.'
    );
    var nome = $("#nome").val();
    var email = $("#email").val();
    var telefone = $("#telefone").val();
    var mensagem = $("#mensagem").val();
    var erros = 0;
    if (nome == "") {
        $("#nome").addClass("errorField");
        erros++;
    } else {
        $("#nome").removeClass("errorField");
    }
    if (email == "" || !IsEmail(email)) {
        $("#email").addClass("errorField");
        erros++;
    } else {
        $("#email").removeClass("errorField");
    }
    if (telefone == "") {
        $("#telefone").addClass("errorField");
        erros++;
    } else {
        $("#telefone").removeClass("errorField");
    }
    if (mensagem == "") {
        $("#mensagem").addClass("errorField");
        erros++;
    } else {
        $("#mensagem").removeClass("errorField");
    }
    if (erros == 0) {
        $(".erroContato").fadeOut("slow");
        $(".sucessoContato").fadeOut("slow");
        $.post(
            url + "/email/form_contato.php",
            $("#formContato").serialize(),
            function(data) {
                if (data == "1") {
                    $("#formContato")[0].reset();
                    $(".sucessoContato").fadeIn("slow");
                    $("#btnEnviarContato").html("Enviar");
                } else {
                    $("#erroRetornoContato").html(data);
                    $(".erroContato").fadeIn("slow");
                    $("#btnEnviarContato").html("Reenviar");
                }
            }
        );
    } else {
        $(".erroContato").fadeIn("slow");
        $("#btnEnviarContato").html("Enviar");
    }
});
//Envia E-mail Agendamento
$("#formAgende").submit(function(e) {
    e.preventDefault();
    $("#btnEnviarAgendamento").html(
        'Aguarde. Enviando.'
    );
    var nome = $("#nomeAgende").val();
    var email = $("#emailAgende").val();
    var telefone = $("#telefoneAgende").val();
    var data = $("#dataAgende").val();
    var erros = 0;
    if (nome == "") {
        $("#nomeAgende").addClass("errorField");
        erros++;
    } else {
        $("#nomeAgende").removeClass("errorField");
    }
    if (email == "" || !IsEmail(email)) {
        $("#emailAgende").addClass("errorField");
        erros++;
    } else {
        $("#emailAgende").removeClass("errorField");
    }
    if (telefone == "") {
        $("#telefoneAgende").addClass("errorField");
        erros++;
    } else {
        $("#telefoneAgende").removeClass("errorField");
    }
    if (data == "") {
        $("#dataAgende").addClass("errorField");
        erros++;
    } else {
        $("#dataAgende").removeClass("errorField");
    }
    if (erros == 0) {
        $(".erroAgende").fadeOut("slow");
        $(".sucessoAgende").fadeOut("slow");
        $.post(
            url + "/email/form_agendamento.php",
            $("#formAgende").serialize(),
            function(data) {
                if (data == "1") {
                    $("#formAgende")[0].reset();
                    $(".sucessoAgende").fadeIn("slow");
                    $("#btnEnviarAgendamento").html("Enviar");
                } else {
                    $("#erroRetorno").html(data);
                    $(".erroAgende").fadeIn("slow");
                    $("#btnEnviarAgendamento").html("Reenviar");
                }
            }
        );
    } else {
        $(".erroAgende").fadeIn("slow");
        $("#btnEnviarAgendamento").html("Enviar");
    }
});
