// $(window).on('scroll', function () {
//     if ($(window).scrollTop() > 350) {
//         $("header").addClass('flutuante');
//     } else if ($(window).scrollTop() < 350) {
//         $("header").removeClass('flutuante');
//     }
// });

// $('#rollLinks').localScroll({
//     target:'body',
//     offset: -97
// });

$('.botaoResponsivo').on('click', function(){
    $('.menu nav').toggleClass('ativo');
    $('.linhasmr').toggleClass('ativo');
    if ($('.linhasmr').hasClass('ativo')) {
        $(".linhasmr").addClass("ativo");
    } else {
        $(".linhasmr").removeClass("ativo");
    }
    return false;
});

$('.menu nav a').on('click', function(){
    $('.menu nav').removeClass('ativo');
    $('.linhasmr').removeClass('ativo');
});